import { useCallback, useContext } from 'react';
import { default as jsonFile } from "./survey.json"
import { countWithoutNones, arrayWithoutNones, maxDateFromSessions, questionValue } from "./custom_survey_functions.js"
import { AuthContext } from 'react-oauth2-code-pkce'

// Default V2 theme
import 'survey-core/defaultV2.min.css';
// Modern theme
// import 'survey-core/modern.min.css';
import { StylesManager, Model, FunctionFactory, settings } from 'survey-core';
import { Survey } from 'survey-react-ui';

// const SURVEY_ID = 1;

StylesManager.applyTheme("defaultV2");

const surveyJson = jsonFile;

function App() {
    const { token, logIn } = useContext(AuthContext)
    console.log("starting App")

    const alertResults = useCallback((sender) => {
        saveSurveyResults(
            "https://eoxmpcsc1751j8t.m.pipedream.net",
            sender.data
        );
    }, []);

    // disable forced authentication for now
    if (1==2 && !token) {
        return (
            <>
                <div>You are not logged in and it is required to continue.</div>
                <button onClick={() => window.location.href = "https://auth.akeyless.io/saml-login?access_id=p-phn829bvtjuasm&redirect_uri=https://poc-plan-tool.cg.cs.akeyless.fans"}>Log in</button>
            </>
        );
    }

    // add a custom header to the request of all choices pulled through REST
    settings.web.onBeforeRequestChoices = (sender, options) => {
        console.log("adding token to request headers")
        options.request.setRequestHeader('Authorization', `Bearer ${token}`);
    };

    const survey = new Model(surveyJson);

    survey.onComplete.add(alertResults);

    return <div className="app-content"><Survey model={survey} /></div>;
}

FunctionFactory.Instance.register("countWithoutNones", countWithoutNones);
FunctionFactory.Instance.register("arrayWithoutNones", arrayWithoutNones);
FunctionFactory.Instance.register("maxDateFromSessions", maxDateFromSessions);
FunctionFactory.Instance.register("questionValue", questionValue);

function saveSurveyResults(url, json) {

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-API-KEY': process.env.AKEYLESS_HEADER_VALUE
        },
        body: JSON.stringify(json)
    })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error(error))

    console.log("saveSurveyResults", JSON.stringify(json, null, 2));
}

export default App;
